
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        




























































@import '@/styles/_colors';

.main {
  width: span(12);
}

.help {
  @media screen and (min-width: bp(l)) {
    display: grid;
    grid-gap: span(0, 1);
    grid-template-columns: repeat(10, 1fr);
  }
}

.help__toc {
  align-self: start;
  @media screen and (min-width: bp(l)) {
    grid-row: 1;
    grid-column: 7 / span 4;
  }
}

.help__content {
  @media screen and (min-width: bp(l)) {
    grid-row: 1;
    grid-column: 1 / span 5;
  }

  @media screen and (min-width: bp(xl)) {
    grid-row: 1;
    grid-column: 1 / span 4;
  }
}

::v-deep a[href*='thumbnail'] img {
  float: left;
  width: 6em;
  height: 6em;
  margin: 1rem;
  object-fit: cover;
  border: 1px solid rgba(black, 0.1);
}

.toc {
  margin: 2rem 0 5rem;
  padding: 1.5rem;
  font-size: 1rem;
  border: 1px solid $color-grey-100;

  li {
    padding: 0.25rem 0;
  }

  ul {
    padding-top: 0.25rem;
    padding-left: 2rem;
  }

  a {
    font-weight: 400 !important;
  }
}
